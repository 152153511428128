.home-style {
  height: 100vh;
  width: 95vw;
  font-size: 50px;
  display: flex;
  padding-left: 5vw;
  align-items: center;
  justify-content: center;
  margin-bottom: 20vh;
}
.home-style-mobile {
  padding-left: 16vw;

  font-size: 35px;
}

.about-style {
  display: flex;
  width: 95vw;
  padding-left: 5vw;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.about-style-mobile {
  background-color: transparent;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.about-header {
  font-size: 50px;
  padding-left: 5vw;
  background-color: transparent;
}

.about-header-mobile {
  background-color: transparent;
  margin-top: 20vh;
  width: 100vw;
}

.about-text {
  background-color: transparent;
  font-size: 20px;
  width: 50vw;
  padding-right: 100px;
  padding-top: 15vh;
}

.about-text-2 {
  margin-top: 30px;
}

.about-social {
  text-decoration: underline;
}

.about-text-mobile {
  font-size: 20px;
  width: 100vw;
  background-color: transparent;
  margin-left: 10vw;
}

.picture-light {
  background-color: transparent;
  background-image: url(../assets/brown_light.jpg);
  background-size: 100%;
  width: 400px;
  height: 600px;
}

.picture-dark {
  background-color: transparent;
  background-image: url(../assets/brown_dark.jpg);
  z-index: -1;
  background-position-y: -80px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: -10vh;
  width: 400px;
  height: 600px;
}

.picture-dark-mobile {
  width: 110vw;
}

.project-style {
  padding-top: 10vh;
  margin-top: 40vh;
  width: 100vw;
  overflow-x: hidden;
}

.project-style-mobile {
  margin-top: 0;
}
.project-cards {
  background-color: transparent;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-top: -30px;
  padding-left: 50px;
  padding-right: 50px;
  width: 100vw;
  margin-bottom: 100px;
}

.project-cards-mobile {
  flex-direction: column;
  align-items: center;
}

.project-header {
  font-size: 50px;
  padding-right: 25vw;
  text-align: end;
}

.project-header-mobile {
  padding-right: 10vw;
}

.social-header {
  font-size: 50px;
  text-align: center;
  transform: translateX(-10vw);
}
.social-style {
  margin-top: 25vh;
}

.social-style-mobile {
  margin-top: 10vh;
}

.social-text {
  max-width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  font-size: 30px;
  background-color: transparent;
  height: 50vh;
  justify-content: space-evenly;
  padding-left: 20vw;
}

.social-text-mobile {
  padding-left: 2vw;
  font-size: 25px;
}

.social-icon {
  transform: translateY(11px);
  margin-right: 15px;
}

.copyright {
  width: 100vw;
  padding: 5vw;
  padding-top: 0vh;
}

.copyright-text {
  overflow-x: hidden;
  font-size: 15px;
  text-align: right;
}

.copyright-text-2 {
  font-size: 15px;
  text-align: right;
  margin-top: -5px;
}
.copyright-icon {
  margin-right: 5px;
  text-align: right;
  transform: translateY(2px);
}

a {
  text-decoration: none;
  cursor: pointer;
}
