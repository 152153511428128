.switch {
  position: relative;
  display: flex;
  width: 80px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.button-container {
  background-color: transparent;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.slider {
  position: absolute;
  cursor: pointer;
  border-radius: 5px;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  transition: 0.8s;
  background-color: #f0ead6;
}

.slider:before {
  position: absolute;
  content: "";
  border-radius: 5px;

  height: 28px;
  width: 28px;
  left: 4px;
  right: 2px;
  bottom: 3px;
  background-color: #2f2f2f;
  transition: 0.8s;
}

input:checked + .slider {
  background-color: #2f2f2f;
}
input:checked + .slider:before {
  background-color: #f0ead6;
  transform: translateX(28px);
}
