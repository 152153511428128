.App {
  color: #2f2f2f;
}

.content-wrapper {
  max-width: 100vw;
  overflow-x: hidden;
}

.navbar-wrapper {
  max-width: 100vw;
  overflow-x: hidden;
}
