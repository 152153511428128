.card-container {
  width: 300px;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0px 0px 10px -10px;
  display: flex;
  flex-direction: column;
  transition: ease-in 0.2s;
  justify-content: space-around;

  margin-top: 30px;
  height: 300px;
}

.card-container:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 15px 0px;
}

.card-title {
  text-align: center;
  font-size: 22px;
}

.card-body {
  margin-top: -20px;
  padding-left: 20px;
  padding-right: 20px;
}

.card-icon {
  overflow: hidden;
  background-color: transparent;
  width: 100%;
  cursor: pointer;
}
