.navbar {
  background-color: transparent;
  width: 100vw;
  overflow-x: hidden;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-right: 40px;
  padding-top: 20px;
  z-index: 10;
}

.navbar-mobile {
  padding-right: 10px;
}

.burger-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5em;
  height: 3.5em;
  border-radius: 20px;
}

#nav-items li {
  list-style-type: none;
  cursor: pointer;
}

.nav-menu {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-top: 100px;
  width: 220px;
  height: 75vh;
  display: flex;
  right: -100%;
  position: fixed;
  justify-content: flex-start;
  align-items: flex-start;
  top: 0;
  transition: 850ms;
  z-index: 10;
}

.nav-menu.active {
  right: 0%;
  transition: 350ms;
  overflow-x: hidden;
}

.nav-text {
  display: flex;
  height: 60px;
  list-style: none;
  height: 60px;
}

.nav-menu-items {
  background-color: transparent;
  font-size: 20px;
  height: 90%;
  width: 100%;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.navbar-toggle {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}
