@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
* {
  box-sizing: border-box;
  max-width: 100vw;
}

html {
  font-family: "Roboto Mono", monospace;
  max-width: 100vw;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

.App {
  color: #2f2f2f;
}

.content-wrapper {
  max-width: 100vw;
  overflow-x: hidden;
}

.navbar-wrapper {
  max-width: 100vw;
  overflow-x: hidden;
}

.switch {
  position: relative;
  display: -webkit-flex;
  display: flex;
  width: 80px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.button-container {
  background-color: transparent;
  font-size: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.slider {
  position: absolute;
  cursor: pointer;
  border-radius: 5px;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  transition: 0.8s;
  background-color: #f0ead6;
}

.slider:before {
  position: absolute;
  content: "";
  border-radius: 5px;

  height: 28px;
  width: 28px;
  left: 4px;
  right: 2px;
  bottom: 3px;
  background-color: #2f2f2f;
  transition: 0.8s;
}

input:checked + .slider {
  background-color: #2f2f2f;
}
input:checked + .slider:before {
  background-color: #f0ead6;
  -webkit-transform: translateX(28px);
          transform: translateX(28px);
}

.navbar {
  background-color: transparent;
  width: 100vw;
  overflow-x: hidden;
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding-right: 40px;
  padding-top: 20px;
  z-index: 10;
}

.navbar-mobile {
  padding-right: 10px;
}

.burger-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 3.5em;
  height: 3.5em;
  border-radius: 20px;
}

#nav-items li {
  list-style-type: none;
  cursor: pointer;
}

.nav-menu {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-top: 100px;
  width: 220px;
  height: 75vh;
  display: -webkit-flex;
  display: flex;
  right: -100%;
  position: fixed;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  top: 0;
  transition: 850ms;
  z-index: 10;
}

.nav-menu.active {
  right: 0%;
  transition: 350ms;
  overflow-x: hidden;
}

.nav-text {
  display: -webkit-flex;
  display: flex;
  height: 60px;
  list-style: none;
  height: 60px;
}

.nav-menu-items {
  background-color: transparent;
  font-size: 20px;
  height: 90%;
  width: 100%;
  padding-top: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.navbar-toggle {
  width: 100%;
  height: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}

span {
  margin-left: 16px;
}

.home-style {
  height: 100vh;
  width: 95vw;
  font-size: 50px;
  display: -webkit-flex;
  display: flex;
  padding-left: 5vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 20vh;
}
.home-style-mobile {
  padding-left: 16vw;

  font-size: 35px;
}

.about-style {
  display: -webkit-flex;
  display: flex;
  width: 95vw;
  padding-left: 5vw;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: transparent;
}

.about-style-mobile {
  background-color: transparent;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100vw;
}

.about-header {
  font-size: 50px;
  padding-left: 5vw;
  background-color: transparent;
}

.about-header-mobile {
  background-color: transparent;
  margin-top: 20vh;
  width: 100vw;
}

.about-text {
  background-color: transparent;
  font-size: 20px;
  width: 50vw;
  padding-right: 100px;
  padding-top: 15vh;
}

.about-text-2 {
  margin-top: 30px;
}

.about-social {
  text-decoration: underline;
}

.about-text-mobile {
  font-size: 20px;
  width: 100vw;
  background-color: transparent;
  margin-left: 10vw;
}

.picture-light {
  background-color: transparent;
  background-image: url(/static/media/brown_light.906c8ea3.jpg);
  background-size: 100%;
  width: 400px;
  height: 600px;
}

.picture-dark {
  background-color: transparent;
  background-image: url(/static/media/brown_dark.6cd15c0e.jpg);
  z-index: -1;
  background-position-y: -80px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: -10vh;
  width: 400px;
  height: 600px;
}

.picture-dark-mobile {
  width: 110vw;
}

.project-style {
  padding-top: 10vh;
  margin-top: 40vh;
  width: 100vw;
  overflow-x: hidden;
}

.project-style-mobile {
  margin-top: 0;
}
.project-cards {
  background-color: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: -30px;
  padding-left: 50px;
  padding-right: 50px;
  width: 100vw;
  margin-bottom: 100px;
}

.project-cards-mobile {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.project-header {
  font-size: 50px;
  padding-right: 25vw;
  text-align: end;
}

.project-header-mobile {
  padding-right: 10vw;
}

.social-header {
  font-size: 50px;
  text-align: center;
  -webkit-transform: translateX(-10vw);
          transform: translateX(-10vw);
}
.social-style {
  margin-top: 25vh;
}

.social-style-mobile {
  margin-top: 10vh;
}

.social-text {
  max-width: 100vw;
  overflow-x: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 30px;
  background-color: transparent;
  height: 50vh;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  padding-left: 20vw;
}

.social-text-mobile {
  padding-left: 2vw;
  font-size: 25px;
}

.social-icon {
  -webkit-transform: translateY(11px);
          transform: translateY(11px);
  margin-right: 15px;
}

.copyright {
  width: 100vw;
  padding: 5vw;
  padding-top: 0vh;
}

.copyright-text {
  overflow-x: hidden;
  font-size: 15px;
  text-align: right;
}

.copyright-text-2 {
  font-size: 15px;
  text-align: right;
  margin-top: -5px;
}
.copyright-icon {
  margin-right: 5px;
  text-align: right;
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}

a {
  text-decoration: none;
  cursor: pointer;
}

.card-container {
  width: 300px;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0px 0px 10px -10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  transition: ease-in 0.2s;
  -webkit-justify-content: space-around;
          justify-content: space-around;

  margin-top: 30px;
  height: 300px;
}

.card-container:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  box-shadow: 0px 0px 15px 0px;
}

.card-title {
  text-align: center;
  font-size: 22px;
}

.card-body {
  margin-top: -20px;
  padding-left: 20px;
  padding-right: 20px;
}

.card-icon {
  overflow: hidden;
  background-color: transparent;
  width: 100%;
  cursor: pointer;
}

