@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

* {
  box-sizing: border-box;
  max-width: 100vw;
}

html {
  font-family: "Roboto Mono", monospace;
  max-width: 100vw;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}
